import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "@material-ui/core/Button"

import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"

import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"

//images
import ada1 from "../images/ada/ada1.jpg"
import ada2 from "../images/ada/ada2.jpg"
import ada3 from "../images/ada/ada3.jpg"
import ada4 from "../images/ada/ada4.jpg"
import ada5 from "../images/ada/ada5.jpg"
import ada6 from "../images/ada/ada6.jpg"

const styles = theme => ({
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    maxWidth: 600,
    margin: "0 auto",
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
})

const cards = [
  {
    img: ada1,
    title: "Home Renovation",
    details:
      "Garage and front-door accessibility with gentle slopes for easy walking or wheelchairs",
    link: { ada1 },
  },
  {
    img: ada2,
    title: "Wheelchair Ramps",
    details: "Gentle  ramp with side-railing",
  },
  {
    img: ada3,
    title: "Railed Ramps",
    details: "Conservative ramp with side-railing",
  },
  {
    img: ada4,
    title: "Showers",
    details: "Gently sloped shower area for wheelchair accessibility",
  },
  {
    img: ada6,
    title: "Bathrooms",
    details: " Open access designs for space and comfort",
  },
]

function Ada(props) {
  const { classes } = props

  return (
    <Layout>
      <div>
        <div className="spaceWaster" />
        <main>
          {/* Hero unit */}
          <div className={classes.heroUnit}>
            <div className={classes.heroContent}>
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="textPrimary"
                gutterBottom
              >
                ADA
              </Typography>
              <Typography
                variant="h6"
                align="center"
                color="textSecondary"
                paragraph
              >
                American Dream Construction has built a wide variety of homes
                and performed handicap accessible renovations in compliance with
                federal ADA regulations.
              </Typography>
              <Typography
                variant="h6"
                align="center"
                color="textSecondary"
                paragraph
              >
                We have built{" "}
                <strong>
                  ramps, graded showers, accessible bathrooms, staircase lifts,
                  and much more
                </strong>{" "}
                to ensure that our clients' home are safe and comfortable.
              </Typography>
              <div className={classes.heroButtons}>
                <Grid container spacing={16} justify="center">
                  <Grid item>
                    <a
                      href="mailto:info@adcalaska.com"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Button variant="contained" color="primary" size="large">
                        Get a quote today!
                      </Button>
                    </a>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <div className={classNames(classes.layout, classes.cardGrid)}>
            {/* End hero unit */}
            <Grid container spacing={40}>
              {cards.map(card => (
                <Grid item key={card} xs={12} sm={12} md={4} lg={4}>
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={card.img}
                      alt={card.title}
                    />

                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {card.title}
                      </Typography>
                      <Typography>{card.details}</Typography>
                    </CardContent>
                    <CardActions>
                      {" "}
                      {/* <Link to={{ pathname: `/ada/${card.link}` }}>
                      <Button size="small" color="primary">
                        View
                      </Button>
                    </Link> */}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </main>
      </div>
    </Layout>
  )
}

Ada.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Ada)
